<template>
  <div style="padding: 20px 20px">
    <!-- 头部选择框 -->
    <el-select v-model="value1" placeholder="本人及下属">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        @change="active"
      ></el-option>
    </el-select>

    <el-select v-model="value2" placeholder="本月" class="time">
      <el-option
        v-for="item in options2"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <!-- 销售简报 -->
    <el-card class="box-card">
      <i
        class="el-icon-notebook-1"
        style="color:background:RGB(204,227,214);margin-right:5px"
      ></i>
      <b>销售简报</b>
      <div style="margin-top: 20px" class="saleNews">
        <el-card
          shadow="always"
          v-for="(info, index) in saleInfo"
          :key="index"
          class="saleItem"
        >
          <div>
            <ul class="saleInfo">
              <!-- 图标 -->
              <li :style="info.bgCol">
                <i :class="info.icon"></i>
              </li>
              <!-- 提示信息 -->
              <li>
                <ul>
                  <li>{{ info.desc }}</li>
                  <li>{{ info.count }}</li>
                </ul>
              </li>
              <!--比较-->
              <li>
                <ul>
                  <li>较上月</li>
                  <li align="center">
                    {{ info.compare }}<span style="color: green">%</span>
                  </li>
                </ul>
              </li>
              <i></i>
            </ul>
          </div>
        </el-card>
      </div>
    </el-card>
    <!-- 排行榜和数据汇总 -->
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card class="box-card">
          <div style="position: relative">
            <i class="el-icon-s-data"></i>
            <b>排行榜</b>
            <h6>对回款金额进行的排行统计</h6>
            <el-dropdown
              style="position: absolute; right: 0; top: -5px"
              trigger="click"
            >
              <el-button
                type="info"
                style="
                  padding: 0;
                  width: 100px;
                  height: 30px;
                  color: black;
                  border: none;
                "
              >
                回款金额
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>回款金额</el-dropdown-item>
                <el-dropdown-item>合同金额</el-dropdown-item>
                <el-dropdown-item>新增客户数</el-dropdown-item>
                <el-dropdown-item>新增联系人</el-dropdown-item>
                <el-dropdown-item>新增跟进记录数</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <h5 style="text-align: center">回款金额排行榜</h5>
            <el-table
              :data="tableData"
              append
              style="
                width: 100%;
                height: 200px;
                border-bottom: 1px solid transparent;
              "
            >
              <el-table-column
                prop="rank"
                label="排名"
                width="180"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                width="180"
              ></el-table-column>
              <el-table-column
                prop="money"
                label="回款金额（元）"
              ></el-table-column>
              <el-table-column
                prop="goal"
                label="目标完成率（%）"
              ></el-table-column>
            </el-table>
            <el-row class="ranking">
              <el-col :span="6">
                <div class="grid-content bg-purple">--</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-light">悟空</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">--</div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple-light">--</div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card class="box-card" style="height: 335px">
          <div>
            <i class="el-icon-s-order"></i>
            <b>数据汇总</b>
            <h6>对于数据收集统计的汇总</h6>
          </div>
          <el-table :data="tableData" style="width: 100%; border: none">
            <el-table-column style="display: none"></el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!-- 遗忘提醒和业绩指标完成率 (回款金额) -->
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card class="box-card">
          <div>
            <i class="el-icon-time"></i>
            <b>遗忘提醒</b>
            <h6>对一段时间内未联系的客户进行分类汇总</h6>
            <el-row :gutter="20">
              <el-col :span="11" style="cursor: pointer">
                <el-card class="box-card">
                  <div @click="showDialogVisible(7)">
                    <p>超过7天未联系的客户</p>

                    <div>
                      <p
                        style="display:inline;font-size:30px;color:background:RGB(35,98,251)"
                      >
                        0
                      </p>
                      &nbsp;
                      <b>个</b>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="11" style="cursor: pointer">
                <el-card class="box-card">
                  <div @click="showDialogVisible(15)">
                    <p>超过15天未联系的客户</p>

                    <div>
                      <p
                        style="display:inline;font-size:30px;color:background:RGB(35,98,251)"
                      >
                        0
                      </p>
                      &nbsp;
                      <b>个</b>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11" style="cursor: pointer">
                <el-card class="box-card">
                  <div @click="showDialogVisible(30)">
                    <p>超过30天未联系的客户</p>

                    <div>
                      <p
                        style="display:inline;font-size:30px;color:background:RGB(35,98,251)"
                      >
                        0
                      </p>
                      &nbsp;
                      <b>个</b>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="11" style="cursor: pointer">
                <el-card class="box-card">
                  <div @click="showDialogVisible(3)">
                    <p>超过3个月未联系的客户</p>

                    <div>
                      <p
                        style="display:inline;font-size:30px;color:background:RGB(35,98,251)"
                      >
                        0
                      </p>
                      &nbsp;
                      <b>个</b>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11" style="cursor: pointer">
                <el-card class="box-card">
                  <div @click="showDialogVisible(6)">
                    <p>超过6个月未联系的客户</p>
                    <div>
                      <p
                        style="display:inline;font-size:30px;color:background:RGB(35,98,251)"
                      >
                        0
                      </p>
                      &nbsp;
                      <b>个</b>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="11" style="cursor: pointer">
                <el-card class="box-card">
                  <div @click="showDialogVisible(0)">
                    <p>逾期未联系的客户</p>

                    <div>
                      <p
                        style="display:inline;font-size:30px;color:background:RGB(35,98,251)"
                      >
                        0
                      </p>
                      &nbsp;
                      <b>个</b>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>

            <el-dialog
              :title="title"
              :visible.sync="dialogVisible"
              width="1000px"
              :before-close="handleClose"
            >
              <el-input
                v-model="input"
                placeholder="请输入内容"
                style="
                  width: 300px;
                  position: absolute;
                  left: 50%;
                  top: 20px;
                  transform: translateX(-50%);
                "
              >
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
              <el-table
                :data="tableData"
                style="height: 300px; position: relative"
                :default-sort="{ prop: 'date', order: 'descending' }"
              >
                <el-table-column
                  prop="customerName"
                  label="客户名称"
                  sortable
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="tel"
                  label="电话"
                  sortable
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="captain"
                  label="负责人"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="customerLevel"
                  label="客户级别"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="customerFrom"
                  label="客户来源"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="dealStatus"
                  label="成交状态"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="creator"
                  label="创建人"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="updateTime"
                  label="更新时间"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="province"
                  label="省、市、区/县"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="detailAddr"
                  label="详细地址"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="customerInDustry"
                  label="客户行业"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="InterWaterTime"
                  label="距进入公海天数"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="lockStatus"
                  label="锁定状态"
                  sortable
                  :formatter="formatter"
                  width="180"
                ></el-table-column>
              </el-table>
              <div style="text-align: center">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="1000"
                ></el-pagination>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
          </div>
        </el-card>
      </el-col>

      <el-col :span="10">
        <el-card class="box-card">
          <div>
            <div style="position: relative">
              <i class="el-icon-s-data"></i>
              <b>合同金额目标及完成情况</b>
              <h6>近一年的合同目标完成情况柱状图</h6>
              <el-dropdown
                style="position: absolute; right: 0; top: -5px"
                trigger="click"
              >
                <el-button
                  type="primary"
                  style="padding:0;width:100px;height:30px;color:black;background:background:RGB(221,221,221);border:none"
                >
                  合同金额
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>回款金额</el-dropdown-item>
                  <el-dropdown-item>合同金额</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>

          <div id="goalTable" style="width: 600px; height: 370px"></div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 销售漏斗和合同金额目标及完成情况 -->
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card class="box-card">
          <div style="position: relative">
            <i
              class="el-icon-reading"
              style="color:background:RGB(204,227,214);margin-right:5px"
            ></i>
            <b>销售漏斗</b>
            <div id="main" style="width: 700px; height: 400px"></div>
            <el-dropdown
              style="position: absolute; right: 0; top: -5px"
              trigger="click"
            >
              <el-button
                type="primary"
                style="padding:0;width:100px;height:30px;color:black;background:background:RGB(221,221,221);border:none"
              >
                售前谈判
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>售前谈判</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-card>
      </el-col>

      <el-col :span="10">
        <el-card class="box-card">
          <div style="position: relative">
            <i class="el-icon-stopwatch"></i>
            <b>业绩指标完成率 (回款金额)</b>

            <el-dropdown
              style="position: absolute; right: 0; top: -5px"
              trigger="click"
            >
              <el-button
                type="primary"
                style="padding:0;width:100px;height:30px;color:black;background:background:RGB(221,221,221);border:none"
              >
                合同金额
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>回款金额</el-dropdown-item>
                <el-dropdown-item>合同金额</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div id="main2" style="width: 600px; height: 400px"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
let echarts = require('echarts');
export default {
  data() {
    return {
      //选项1的属性
      options: [
        {
          value: '仅本人',
          label: '仅本人',
        },
        {
          value: '本人及下属',
          label: '本人及下属',
        },
        {
          value: '仅本部门',
          label: '仅本部门',
        },
        {
          value: '本部门及下属部门',
          label: '本部门及下属部门',
        },
        {
          value: '自定义',
          label: '自定义',
        },
      ],
      value1: '',
      value2: '',
      //选项2的属性
      options2: [
        {
          value: '选项1',
          label: '今天',
        },
        {
          value: '选项2',
          label: '昨天',
        },
        {
          value: '选项3',
          label: '本周',
        },
        {
          value: '选项4',
          label: '上周',
        },
        {
          value: '选项5',
          label: '本月',
        },
        {
          value: '选项6',
          label: '上月',
        },
        {
          value: '选项7',
          label: '本季度',
        },
        {
          value: '选项8',
          label: '上季度',
        },
        {
          value: '选项9',
          label: '本年',
        },
        {
          value: '选项10',
          label: '去年',
        },
        {
          value: '选项11',
          label: '自定义',
        },
      ],
      //销售简报的数据
      saleInfo: {
        //新增客户
        addCtm: {
          count: 0, //新增客户人数
          compare: 0, //较上月
          desc: '新增客户(人)', //描述
          icon: 'el-icon-office-building', //图标
          bgCol: 'background:RGB(35,98,251)',
          list: [
            '客户名称',
            '电话',
            '负责人',
            '客户级别',
            '客户来源',
            '创建人',
            '更新时间',
            '省、市、区/县',
            '创建时间',
            '详细地址',
            '客户行业',
            '成交状态',
            '跟进记录',
            '备注',
            '网址',
            '下次联系时间',
            '邮箱',
            '最后跟进时间',
            '手机',
            '距进入公海天数',
            '锁定状态',
          ],
        },
        //新增联系人(人)
        addCon: {
          count: 0, //新增联系人(人)
          compare: 0, //较上月
          desc: '新增联系人(人)', //描述
          icon: 'el-icon-user-solid', //图标
          bgCol: 'background:RGB(39,186,74)',
          list: [
            '姓名',
            '客户名称',
            '手机',
            '电话',
            '电子邮箱',
            '是否关键决策人',
            '职务',
            '性别',
            '地址',
            '下次联系时间',
            '备注',
            '创建人',
            '创建时间',
          ],
        },
        //新增商机(个)
        addBis: {
          count: 0, //新增商机(个)
          compare: 0, //较上月
          desc: '新增商机(个)', //描述
          icon: 'el-icon-phone', //图标
          bgCol: 'background:RGB(251,147,35)',
          list: [
            '商机名称',
            '客户名称',
            '商机状态组',
            '商机金额',
            '预计成交日期',
            '备注',
            '创建人',
            '更新时间',
            '创建时间',
            '负责人',
            '跟进记录',
            '最后跟进时间',
          ],
        },
        //新增合同
        addCrt: {
          count: 0, //新增合同(个)
          compare: 0, //较上月
          desc: '新增合同(个)', //描述
          icon: 'el-icon-tickets', //图标
          bgCol: 'background:RGB(74,91,253)',
          list: [
            '客户名称',
            '商机名称',
            '下单时间',
            '合同开始时间',
            '合同到期时间',
            '客户签约人',
            '公司签约人',
            '备注',
            '创建人',
            '更新时间',
            '创建时间',
            '负责人',
            '审核状态',
            '跟进记录',
            '最后跟进时间',
            '已回款',
            '未回款',
            '合同编号',
            '合同名称',
            '合同金额',
          ],
        },
        //合同金额(元)
        addCrtMey: {
          count: 0, //合同金额(元)
          compare: 0, //较上月
          desc: '合同金额(元)', //描述
          icon: 'el-icon-pie-chart', //图标
          bgCol: 'background:RGB(25,181,246)',
          list: [
            '客户名称',
            '商机名称',
            '下单时间',
            '合同开始时间',
            '合同到期时间',
            '客户签约人',
            '公司签约人',
            '备注',
            '创建人',
            '更新时间',
            '创建时间',
            '负责人',
            '审核状态',
            '跟进记录',
            '最后跟进时间',
            '已回款',
            '未回款',
            '合同编号',
            '合同名称',
            '合同金额',
          ],
        },
        //商机金额(元)
        addBisMey: {
          count: 0, //商机金额(元)
          compare: 0, //较上月
          desc: '商机金额(元)', //描述
          icon: 'el-icon-data-analysis', //图标
          bgCol: 'background:RGB(173,92,255)',
          list: [
            '商机名称',
            '客户名称',
            '商机状态组',
            '商机金额',
            '预计成交日期',
            '备注',
            '创建人',
            '更新时间',
            '创建时间',
            '负责人',
            '跟进记录',
            '最后跟进时间',
          ],
        },
        //回款金额(元)
        addRceMey: {
          count: 0, //回款金额(元)
          compare: 0, //较上月
          desc: '回款金额(元)', //描述
          icon: 'el-icon-coin', //图标
          bgCol: 'background:RGB(251,182,63)',
          list: [
            '回款日期',
            '回款方式',
            '回款金额',
            '期数',
            '备注',
            '备注',
            '创建人',
            '更新时间',
            '创建时间',
            '合同金额',
          ],
        },
        //新增跟进记录(人)
        addRrd: {
          count: 0, //新增跟进记录(人)
          compare: 0, //较上月人数
          desc: '新增跟进记录(人)', //描述
          icon: 'el-icon-files', //图标
          bgCol: 'background:RGB(74,91,253)',
          list: {
            head: {
              name: '模块',
              countName: '新增跟进记录数',
            },
            clues: {
              clue: {
                name: '线索',
                count: 0,
              },
              cmr: {
                name: '客户',
                count: 0,
              },
              cnt: {
                name: '联系人',
                count: 0,
              },
              bis: {
                name: '商机',
                count: 0,
              },
              ctr: {
                name: '合同',
                count: 0,
              },
            },
          },
        },
      },
      dialogVisible: false,
      title: '',
    };
  },
  created() {},
  //dom元素初始化完毕
  mounted() {
    //销售漏斗图
    var funnel = () => {
      var myChart = echarts.init(document.getElementById('main'));
      // 指定销售漏斗的配置项和数据
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%',
        },
        legend: {
          data: ['展现', '点击', '访问', '咨询', '订单'],
        },
        series: [
          {
            name: '预期',
            type: 'funnel',
            left: '10%',
            width: '80%',
            label: {
              formatter: '{b}预期',
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              opacity: 0.7,
            },
            emphasis: {
              label: {
                position: 'inside',
                formatter: '{b}预期: {c}%',
              },
            },
            data: [
              { value: 60, name: '访问' },
              { value: 40, name: '咨询' },
              { value: 20, name: '订单' },
              { value: 80, name: '点击' },
              { value: 100, name: '展现' },
            ],
          },
          {
            name: '实际',
            type: 'funnel',
            left: '10%',
            width: '80%',
            maxSize: '80%',
            label: {
              position: 'inside',
              formatter: '{c}%',
              color: '#fff',
            },
            itemStyle: {
              opacity: 0.5,
              borderColor: '#fff',
              borderWidth: 2,
            },
            emphasis: {
              label: {
                position: 'inside',
                formatter: '{b}实际: {c}%',
              },
            },
            data: [
              { value: 30, name: '访问' },
              { value: 10, name: '咨询' },
              { value: 5, name: '订单' },
              { value: 50, name: '点击' },
              { value: 80, name: '展现' },
            ],
            // Ensure outer shape will not be over inner shape when hover.
            z: 100,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    };
    funnel();
    //合同金额目标及完成情况柱形图
    var goal = () => {
      var app = {};
      var chartDom = document.getElementById('goalTable');
      var goalTable = echarts.init(chartDom);
      var option2;
      var posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight',
      ];

      app.configParameters = {
        rotate: {
          min: -90,
          max: 90,
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right',
          },
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom',
          },
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {}),
        },
        distance: {
          min: 0,
          max: 100,
        },
      };

      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          var labelOption = {
            normal: {
              rotate: app.config.rotate,
              align: app.config.align,
              verticalAlign: app.config.verticalAlign,
              position: app.config.position,
              distance: app.config.distance,
            },
          };
          goalTable.setOption({
            series: [
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
            ],
          });
        },
      };

      var labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {},
        },
      };

      option2 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: ['Forest', 'Steppe'],
        },
        //工具箱
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: false },
            dataView: { show: false, readOnly: false },
            magicType: { show: false, type: ['line', 'bar', 'stack', 'tiled'] },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        //x轴
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['2012', '2013', '2014', '2015', '2016'],
          },
        ],
        //y轴
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: 'Forest',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: [320, 332, 301, 334, 390],
          },
          {
            name: 'Steppe',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: [220, 182, 191, 234, 290],
          },
        ],
      };

      option2 && goalTable.setOption(option2);
    };
    goal();
    //业绩指标完成率 (回款金额)
    var goal2 = () => {
      var chartDom = document.getElementById('main2');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        series: [
          {
            type: 'gauge',
            anchor: {
              show: true,
              showAbove: true,
              size: 18,
              itemStyle: {
                color: '#FAC858',
              },
            },
            pointer: {
              icon:
                'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
              width: 8,
              length: '80%',
              offsetCenter: [0, '8%'],
            },

            progress: {
              show: true,
              overlap: true,
              roundCap: true,
            },
            axisLine: {
              roundCap: true,
            },
            data: [
              {
                value: 20,
                name: '完成率',
                title: {
                  offsetCenter: ['-40%', '80%'],
                },
                detail: {
                  offsetCenter: ['-40%', '95%'],
                },
              },
              {
                value: 40,
                name: '达标率',
                title: {
                  offsetCenter: ['0%', '80%'],
                },
                detail: {
                  offsetCenter: ['0%', '95%'],
                },
              },
              {
                value: 60,
                name: '优秀率',
                title: {
                  offsetCenter: ['40%', '80%'],
                },
                detail: {
                  offsetCenter: ['40%', '95%'],
                },
              },
            ],
            title: {
              fontSize: 14,
            },
            detail: {
              width: 40,
              height: 14,
              fontSize: 14,
              color: '#fff',
              backgroundColor: 'auto',
              borderRadius: 3,
              formatter: '{value}%',
            },
          },
        ],
      };

      setInterval(function () {
        option.series[0].data[0].value = (Math.random() * 100).toFixed(2) - 0;
        option.series[0].data[1].value = (Math.random() * 100).toFixed(2) - 0;
        option.series[0].data[2].value = (Math.random() * 100).toFixed(2) - 0;
        myChart.setOption(option, true);
      }, 2000);

      option && myChart.setOption(option);
    };
    goal2();
  },
  methods: {
    active: e => {
      console.log(e);
    },
    showDialogVisible(day) {
      console.log(1);
      this.dialogVisible = true;
      if (day === 3 || day === 6) {
        this.title = `超过${day}个月没有联系的人`;
        return;
      }
      if (day === 0) {
        this.title = `逾期没有联系的人`;
        return;
      }
      this.title = `超过${day}天没有联系的人`;
    },
    btnClickHandler() {},
  },
};
</script>

<style scoped>
.time {
  margin-left: 30px;
  width: 200px;
}
.box-card {
  margin-top: 20px;
}
ul {
  display: inline-block;
}
/*销售简报*/
.saleNews {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 50%);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}
.saleItem {
  cursor: pointer;
}

.saleInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.saleInfo > li:nth-of-type(1) {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  line-height: 60px;
  margin-right: 10px;
}
.saleInfo > li:nth-of-type(1) > i {
  font-size: 35px;
}

.saleInfo > li:nth-of-type(3) {
  position: absolute;
  right: 0;
}
.saleInfo > i {
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 30px;
  background: grey;
  right: 70px;
}
ul {
  list-style-type: none;
}
</style>
